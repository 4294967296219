import Vue from "vue";
import VueRouter from "vue-router";
import layout from "@/views/layout/layout"
import intelligentrouterData from "./modules/intelligentrouter.js"

import storts from "../store/index"
import {
	infos
} from "../api/login.js"
Vue.use(VueRouter);

const routes = [
	{
		path: "",
		name: "login",
		hidden: true,
		meta: {
			title: '你好，请登录',
		},
		component: () => import('@/views/login.vue')
	}, {
		path: "/index",
		name: "index",
		hidden: true,
		meta: {
			title: '宝智达冷链系统v3.0',
		},
		component: () => import('@/views/layout/index.vue')
	},
	{
		path: '*',
		name: "error",
		hidden:true,
		component: () => import('@/components/error.vue'),
	},
	{
		path: "/layout",
		name: "layout",
		hidden: false,
		redirect: '/home/workbench',
		meta: {
			title: '工作台',
		},
		component: layout,
		children: [{
			path: '/home',
			name: 'home',
			redirect: '/home/workbench',
			icon: "icon-shouye",
			component: () => import('@/views/workbench/index.vue'),
			meta: {
				title: '首页',
			},
			children: [{
				path: '/home/workbench',
				name: 'workbench',
				icon: "icon-shouye",
				component: () => import('@/views/workbench/workbench.vue'),
				meta: {
					title: '工作台',
				},
			}]
		}]
	},
	...intelligentrouterData.intelligentrouterData
];

const router = new VueRouter({
	mode: "hash", //hash
	base: process.env.BASE_URL,
	routes,
});
//全局后置路由守卫----初始化时执行，每次路由切换后执行
// router.afterEach(async (to,from)=>{
// 	if(to.path=='/index' && from.path=='/companyManagement/Company' ){//登录进来的
// 		//console.log('后置守卫',)
// 		router.options.routes[3].children = []
// 		storts.dispatch('SETNAV', []) //缓存到vuex
		
// 		let res = await getNavData();
// 		//console.log('柔柔弱弱',res)
// 		//处理路由配置数据
// 		let dynamicRouter = addDynamic(res)
// 		//动态添加路由
// 		router.addRoutes(dynamicRouter)
		
// 		//vuex数据缓存
// 		storts.dispatch('SETNAV', res) //缓存到vuex
// 	}
// })
//登录拦截//未登录返回到登录界面
router.beforeEach(async (to, from, next) => {
	console.log('前置守卫',storts.state.nav)
    storts.dispatch('SETROUTE', from);
	if(storts.state.nav.length==0){
		if(to.path == '/'){
			next()
		}else{
			//获取数据
			let res = await getNavData();
			//处理路由配置数据
			let dynamicRouter = addDynamic(res)
			//动态添加路由
			console.log('========动态添加路由',dynamicRouter)
            router.addRoutes(dynamicRouter)
			
			//vuex数据缓存
			storts.dispatch('SETNAV', res) //缓存到vuex
			//判断是否有标题，赋值标题
			to.meta.title ? document.title = to.meta.title : document.title = '宝智达-冷链系统3.0'
			next({path:to.path})//不写刷新会白屏

		}
	}else{
        console.log('33333',to, from)
		if(to.path == '/'){
			storts.dispatch('SETPANY', {T_name:'',bl:false,plan:''}) //显示公司  登录页不显示
			storts.dispatch('SETNAV', []) //缓存到vuex
		}
		next()//不写刷新会白屏
	}
})
//获取数据
function getNavData(){
	//console.log('重新获取数据')
	return new Promise(resolve=>{
		infos({}).then(res => {
			const {Permission:resIt} = res.data.Data
			storts.dispatch('SETPER', resIt) //缓存权限到vuex
			resolve(res.data.Data.Data)
		})
	})
}
// function addDynamic(routes) {
//     return routes.map(route => {  
//         // if(route.Children.length!=0){
//         //     console.log('还有Children',route.Children)
//         //     const transformedRoute = {  
//         //         ...route,  
//         //         path: `/${route.T_name}`, // 生成path，将T_name转换为小写并用短横线替换空格  
//         //         name: route.T_permission, // 生成name，通常与T_permission相同  
//         //         icon: route.T_icon,
//         //         component: () => import(`@/views/${route.T_permission}.vue`), // 假设views目录下有与T_name对应的组件  
//         //         scrl:`@/views/${route.T_permission}.vue`,
//         //         meta: {  
//         //             title: route.T_name,  
//         //         },  
//         //         children: route.Children ? addDynamic(route.Children) : [] // 递归处理子路由  
//         //     };  
//         //     addDynamic(route.Children)

//         // }else{
//         //     console.log('没有Children')
//         // }
//         // 转换当前路由项  
//         console.log('没有Children',route)
//         const transformedRoute = {  
//             ...route,  
//             path: `/${route.T_name}`, // 生成path，将T_name转换为小写并用短横线替换空格  
//             name: route.T_permission, // 生成name，通常与T_permission相同  
//             icon: route.T_icon,
//             component: () => import(`@/views/${route.T_permission}.vue`),
//             T_permission:route.T_permission,
//             meta: {  
//                 title: route.T_name,  
//             },  
//             children: route.Children ? addDynamic(route.Children) : [] // 递归处理子路由  
//         };  
  
//         // delete transformedRoute.T_permission;  
//         // delete transformedRoute.Children; // 注意：这里的Children已被替换为处理过的children  
//         return transformedRoute;  
//     });  
// } 
function addDynamic(result) {
	routes[3].children = []
	result.forEach(item=>{
		routes[3].children.push({
			path: '/'+item.T_permission,
			name: item.T_permission || '',
			icon: item.T_icon,
			component: () => import(`@/views/${item.T_permission}/index.vue`),
			meta: {
				title: item.T_name,
			},
			children:addDynamicEr(item),
			// redirect:addDynamicEr(item)[0].path
		})
	})
	//console.log('动态路由9999999999999999999',routes)
	return routes
}

function addDynamicEr(result){
	var arr = []
	result.Children.forEach(item=>{
		arr.push({
			path:  '/' + result.T_permission + "/" + item.T_permission,
			name: item.T_permission || '',
			icon: item.T_icon,
			component: () => import(`@/views/${result.T_permission}/${item.T_permission}.vue`),
			meta: {
				title: item.T_name,
			},
			Children: item.Children
		})
	})
	return arr
}

export default router;

<template>
    <div class="MovingModel">
        <el-button type="primary" @click="showDrawer">设备管理</el-button>
        <el-drawer title="设备管理" :visible.sync="drawer" size="90%" :append-to-body="true">
            <div class="MovingModel_content">
                <el-button icon="el-icon-plus" style="width: 200px;flex-shrink: 0;"
                    @click="contextstrFun('添加设备')">添加设备</el-button>
                <div class="MovingModel_content_item">
                    <el-tabs v-model="activeName" tab-position="left" type="border-card" style="height: 100%;">
                        <el-tab-pane :label="item.T_sn" :name="JSON.stringify(index)" v-for="item, index in TabJsonArr" :key="index">
                            <el-descriptions class="margin-top" title="基本信息" :column="3" border style="margin-bottom: 20px;">
                                <template slot="extra">
                                    <el-button type="danger" size="small" @click="delectFun(item)">删除设备</el-button>
                                </template>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <i class="el-icon-notebook-2"></i>
                                        设备分类:
                                    </template>
                                    <div style="cursor: pointer;" @click="clicktabs('编辑设备',item)">
                                        {{item.T_tab}}
                                        <i class="el-icon-edit" style="padding-left: 5px;"></i>
                                    </div>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <i class="el-icon-mobile-phone"></i>
                                        在线状态
                                    </template>
                                    <el-tag size="small" :type="item.T_online!= 1?'danger':''"> {{item.T_online!= 1 ? '离线':'在线'}}</el-tag>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <i class="el-icon-time"></i>
                                        添加时间
                                    </template>
                                    {{item.CreateTime}}
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <i class="el-icon-guide"></i>
                                        设备编号
                                    </template>
                                    <el-tag size="small">
                                    {{item.T_sn}}
                                    </el-tag>
                                </el-descriptions-item>
                                <el-descriptions-item>
                                    <template slot="label">
                                        <i class="el-icon-set-up"></i>
                                        设备类型
                                    </template>
                                    {{item.T_ProductID}}
                                </el-descriptions-item>
                            </el-descriptions>
                            <el-table :data="setTableData(item)" :key="Math.random()" row-key="newId" max-height="600"
                                border :tree-props="{ children: 'children', children: 'children' }">
                                <el-table-column prop="T_name" label="属性名称" width="200" :show-overflow-tooltip="true">
                                </el-table-column>
                                <el-table-column prop="T_tab" label="属性标识符" width="100" :show-overflow-tooltip="true">
                                </el-table-column>
                                <el-table-column label="属性类型" width="150" :show-overflow-tooltip="true">
                                    <template slot-scope="scope">
                                        {{
                                            scope.row.T_type == 1 ? 'number(数值)' :
                                            scope.row.T_type == 2 ? 'string(字符串)' :
                                            scope.row.T_type == 3 ? 'string(字符串)' :
                                            scope.row.T_type == 4 ? 'boolean(布尔值)' :
                                            scope.row.T_type == 8 ? 'time(时间)' :
                                            scope.row.T_type == 10 ? 'object(对象)' : ''
                                        }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="只读状态" width="80" :show-overflow-tooltip="true">
                                    <template slot-scope="scope">
                                        {{ scope.row.T_read ? '只读' : '读写' }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="扩展选项" width="200" :show-overflow-tooltip="true">
                                    <template slot-scope="scope">
                                        <!-- T_type: 1 数值, 2 字符串  3 枚举 4 布尔  8 时间 10对象 -->
                                        <div v-if="[1, 2].includes(scope.row.T_type)">
                                            {{ scope.row.T_attr.dvalue ? '默认值：' + scope.row.T_attr.dvalue : '' }}
                                            {{ scope.row.T_attr.unit ? '单位：' + scope.row.T_attr.unit : '' }}
                                            {{ scope.row.T_attr.accuracy ? '精度：' + scope.row.T_attr.accuracy : '' }}
                                            {{ scope.row.T_attr.min ? '最小值：' + scope.row.T_attr.min : '' }}
                                            {{ scope.row.T_attr.max ? '最大值：' + scope.row.T_attr.max : '' }}
                                        </div>

                                        <!-- <div v-if="scope.row.T_type == 3 || scope.row.T_type == 4">{{ JSON.stringify(scope.row.T_attr).slice(1, -1) }}</div> -->
                                        <div v-if="[3, 4].includes(scope.row.T_type)">{{ JSON.stringify(scope.row.T_attr).slice(1, -1) }}</div>
                                        <div v-if="scope.row.T_type == 8" style="width: 100%;">
                                            {{ scope.row.T_attr.dvalue ? '默认值：' + scope.row.T_attr.dvalue : '' }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="当前值" :show-overflow-tooltip="true">
                                    <template slot-scope="scope">
                                        {{ initDictpresent(item.T_dataJson, scope.row) }}
                                        <!-- <div v-if="[1,2,3,4,8].includes(scope.row.T_type)">{{ scope.row.newId || filterFun(scope.row.newId) }}</div> -->
                                    </template>
                                </el-table-column>
                                <el-table-column label="操作" fixed="right">
                                    <template slot-scope="scope">
                                        <el-button size="mini" @click="handleEdit(item, scope.row)">编辑</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
            <el-drawer title="编辑" :append-to-body="true" :visible.sync="innerDrawer">
                <div style="padding: 20px;">
                    <el-form ref="form.T_data">
                        <el-form-item :label="item.label"
                        v-for="item, index in isFormList" :key="index">
                            <el-input v-model="item.value" @blur="blurFun(item)"></el-input>
                            <p style="font-size: 12px;color: red" v-if="blurFun(item)">请输入true或者false</p>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="onSubmit">立即提交</el-button>
                            <el-button @click="innerDrawer = false">取消</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </el-drawer>
        </el-drawer>
    </div>
</template>

<script>
import {
    DevicepushFun,DevicedeleteFun
} from "../js/MovingRingApi.js";
import { arrMov,steTableDataFn } from "../js/MovingData.js";
export default {
    props: {
        JsonArr: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            activeName:'0',
            drawer: false,
            innerDrawer: false,
            form: {
                T_uuid: '0b7a6a23',
                T_sn: "2024168317603592",
                T_password: "TpJWG4hr8iNuEdbP",
                T_data: {}
            },
            settab: '',
            isFormList: [],
            TabJsonArr: []
        }
    },
    watch: {
        JsonArr: {
            handler(newData) {
                let array = []
                arrMov.forEach(obj => {
                    array.push(...obj.key);
                });
                let arr = [...newData]
                // for (let i = 0; i < arr.length; i++) {
                //     for (let j = 0; j < arr[i].JsonArray.length; j++) {
                //         let tableJson = arr[i].JsonArray[j].T_ProductJson.T_TabDataJson
                //         for (let g = 0; g < tableJson.length; g++) {
                //             if (!array.includes(tableJson[g].T_tab)) {
                //                 arr[i].JsonArray[j].T_ProductJson.T_TabDataJson.splice(g, 1)
                //             }
                //         }
                //     }
                // }
                // let reslut = arr.map(obj => obj.JsonArray);  
                console.log('789',arr) 

                let reslut = arr.map(item => { 
                    console.log('123',item) 
                    return item.arrs.map(subItem => {  
                        // 合并 T_sn 到每个子对象  
                        return { ...subItem, T_tab: item.T_tab };  
                    });  
                }).flat(); // 如果环境支持 flat 方法


                this.activeName = '0'
                this.TabJsonArr = reslut
                console.log('数据变化1', reslut)
            }, deep: true
        }
    },
    methods: {
        setTableData(data){
            return steTableDataFn(data)
        },
        blurFun(data){
            console.log('121',data.types,data)
            if(data.types==4 && !['true','false'].includes(data.value)){
                return true
            }
        },
        delectFun(event){
            console.log('删除',event)
            let self = this
            DevicedeleteFun({T_sn:event.T_sn}).then(res=>{
                if(res.Code==200){
                    self.$emit('confirm')
                }
            })
        },
        clicktabs(titles,item){
            console.log('点击',item)
            this.$emit('editstr',titles,item)
        },
        contextstrFun() {
            this.$emit('addstr', '添加设备')
        },
        showDrawer() {
            this.drawer = true
            this.activeName = '0'

        },
        returnBoolean(value){  
            if(value=="true"){
                return Boolean(true)
            }else{
                return Boolean(false)
            }
        },
        /***
         * 提交
         */
        onSubmit() {
            const rules = this.isFormList.filter(item => item.types==4)
            let values = rules.map(rule => rule.value); 
            const b = values.every(item => item=='true' || item=='false')
           if(!b){
                this.$message.error('请检查是否存在输入了错误参数')
                return
           }else{
                var arrData = [...this.isFormList]
                let obj = {...this.form}
                for (let j = 0; j < arrData.length; j++) {
                    // let isData = arrData[j].types==4? this.returnBoolean(arrData[j].value): arrData[j].value
                    let isData =arrData[j].value=='true' || arrData[j].value=='false'? this.returnBoolean(arrData[j].value): arrData[j].value

                    this.$set(obj.T_data[this.settab], arrData[j].label, isData)
                }
                console.log('单个1',obj.T_data)

                obj.T_data = JSON.stringify(obj.T_data)
                // console.log('单个2',obj.T_data)
                DevicepushFun(obj).then(res => {
                    if (res.Code == 200) {
                        this.innerDrawer = false
                        this.$message.success('编辑成功')
                        this.$emit('confirm')
                    }
                })
           }
        },
        handleEdit(keyis, obj) {
            let self = this
            this.innerDrawer = true
            this.form.T_uuid = keyis.T_ProductJson.T_uuid
            this.form.T_sn = keyis.T_sn
            this.form.T_password = keyis.T_password
            this.form.T_data = {}
            let isSplit = obj.newId.split('.')
            if (isSplit.length == 1) {
                self.settab = isSplit[0].slice(0, isSplit[0].length - 1)
            } else {
                self.settab = isSplit[0]
            }

            this.isFormList = []
            let array = [...obj.children]
            let findArr = [...keyis.T_dataJson]
            this.$set(this.form.T_data, self.settab, {})
            if (obj.T_type == 10) {
                array.forEach(el => {
                    console.log('type',el)
                    let swi = el.newId.split('.')
                    for (let i = 0; i < findArr.length; i++) {
                        if (findArr[i].keys == swi[0]) {
                            let iftype = typeof (findArr[i][swi[1]])
                            console.log('==============type',el)
                            self.isFormList.push({ 
                                label: el.T_tab, 
                                value: iftype == 'boolean' ? JSON.stringify(findArr[i][swi[1]]) : findArr[i][swi[1]],
                                types: el.T_type
                            })
                            break;
                        }
                    }
                });

            } else {
                for (let j = 0; j < findArr.length; j++) {
                    if (isSplit[0] == findArr[j].keys) {
                        let iftype = typeof (findArr[j][isSplit[1]])
                        self.isFormList.push({ 
                            label: isSplit[1], 
                            value: iftype == 'boolean' ? JSON.stringify(findArr[j][isSplit[1]]) : findArr[j][isSplit[1]],
                            types: obj.T_type
                        })
                        break;
                    }
                }
            }

            console.log('编辑', this.isFormList, this.form)
        },
        initDictpresent(arr, str) {
            let isSplit = str.newId.split('.')
            let is_tab;
            if (isSplit.length == 1) {
                is_tab = isSplit[0].slice(0, isSplit[0].length - 1)
                return ''
            } else {
                is_tab = isSplit
                const b = arr.find(item => item.keys == isSplit[0])
                if(b){
                    return b[isSplit[1]]
                }else{
                    return []
                }
            }
        },
    }
}
</script>
<style lang="scss" scoped>
::v-deep .el-tabs--border-card {
    background: #FFF;
    border: 1px solid #DCDFE6;
    box-shadow: 0 0 0 0 #fff;
}
::v-deep .el-table__header {  
  background-color: #f0f0f0 !important;  
} 
</style>
<style lang="scss">
.MovingModel {
    &_content {
        padding: 0 20px;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        &_item {
            padding: 20px 0;
            flex: 1;
            overflow-y: auto;
        }
    }
}
</style>
<template>
	<div class="dataShow">
		<div class="dataShow-T">
			<div class="dataShow-TL">
				<Breadcrumb class="bre"></Breadcrumb>
				<div class="dataShow-searchs">
					<inputs @inputs="inputserValue" @selects="selectsValue" :inputShow="true" :selectShow='true'
						inputPlace0="请输入名称" inputPlace="请输入SN/设备名称" :options="options"></inputs>
					<div class="dataShow-tit-mai1-btn">
						<el-date-picker v-model="TimeValue" type="datetimerange" range-separator="至"
							start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"
							@change="Timechange">
						</el-date-picker>
						<!-- <el-button type="danger" icon="el-icon-search">查询</el-button> -->
					</div>
				</div>
			</div>
			<div class="dataShow-TR">
				<div class="dataShow-searchsMai">
					<el-button v-permission="'dataShow:Backup'" type="info" size="small" @click="backup">备份数据</el-button>
					<el-button v-permission="'dataShow:Excel'" type="warning" size="small" @click="DowDataExcel">导出Excel</el-button>
					<el-button v-permission="'dataShow:PDF'" type="primary" size="small" @click="DowDataPDF">下载PDF</el-button>
				</div>
			</div>
		</div>
		<div class="dataShow-B">
			<div class="dataShow-B-nav">
				<div class="dataShow-B-nav1">
					<div class="dataShow-B-nav1-L">
						<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">
							全选</el-checkbox>
						<div class="dataShow-B-nav1-L1" @click="theChange">反选</div>
					</div>
					<div class="dataShow-B-nav1-R">

					</div>
				</div>
				<div class="dataShow-B-nav2" v-infinite-scroll="load" infinite-scroll-immediate="false">
					<div class="dataShow-B-nav2-mai">
						<el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
							<el-checkbox v-for="(item,index) in cities" :label="item" :key="index">
								<div class="dataShow-B-nav2-t">
									<div class="dataShow-B-nav2-tName" :title="item.T_name">
										{{item.T_name}}
									</div>
									<div class="dataShow-B-nav2-tSn">
										{{item.T_sn}}
									</div>
								</div>
							</el-checkbox>
						</el-checkbox-group>
					</div>
				</div>
				<div class="dataShow-B-nav3">
					<div>
						总量：{{total}}
					</div>
					<div style="text-align: center;">页码：{{pages.page}}/{{Page_size}}</div>
				</div>
			</div>
			<div class="dataShow-B-table">
				<div class="dataShow-B-table2-mai">
					<div class="dataShow-B-table2">
						<el-table :data="tableData" v-loading='showTBLoad' border style="width: 100%">
							<template slot="empty">
								<noData></noData>
							</template>
							<el-table-column label="传感器名称" width="250px" :show-overflow-tooltip="true">
								<template slot-scope="scope">
									{{scope.row.T_name}}[{{scope.row.T_id}}]
								</template>
							</el-table-column>
							<el-table-column label="温度℃" width="80px" :show-overflow-tooltip="true">
								<template slot-scope="scope" style="background: red;">
									<div :class="scope.row.T_ist==1?
                                    scope.row.T_free==1?'':(scope.row.T_tl<=scope.row.T_t && scope.row.T_tu>=scope.row.T_t ?'':'errColor')
                                    :''">
										{{scope.row.T_ist==1?scope.row.T_t:'-'}}
									</div>
								</template>
							</el-table-column>
							<el-table-column label="湿度%" width="80px" :show-overflow-tooltip="true">
								<template slot-scope="scope" style="background: red;">
									<div :class="scope.row.T_ish==1?
                                    scope.row.T_free==1?'':(scope.row.T_rhl<=scope.row.T_rh && scope.row.T_rhu>=scope.row.T_rh?'':'errColor'):''">
										{{scope.row.T_ish==1?scope.row.T_rh:'-'}}
									</div>
								</template>
							</el-table-column>
							<el-table-column label="温度范围 ℃" width="150px" :show-overflow-tooltip="true">
								<template slot-scope="scope">
									{{scope.row.T_ist==1?scope.row.T_tl+'~'+scope.row.T_tu:'-'}}
								</template>
							</el-table-column>
							<el-table-column label="湿度范围 %" width="150px" :show-overflow-tooltip="true">
								<template slot-scope="scope">
									{{scope.row.T_ish==1?scope.row.T_rhl+'~'+scope.row.T_rhu:'-'}}
								</template>
							</el-table-column>
							<el-table-column prop="T_time" label="记录时间" :show-overflow-tooltip="true">
							</el-table-column>
							<el-table-column prop="T_remark" label="备注" :show-overflow-tooltip="true">
							</el-table-column>
						</el-table>
					</div>
					<div class="dataShow-B-table3">
						<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
							:current-page="pages1.page" :page-size="pages1.page_z" :hide-on-single-page="hideSin"
							layout="total, sizes, prev, pager, next, jumper" :total="total1">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
		<el-dialog title="数据备份" :visible.sync="diaBackupShow" width="1000px" :append-to-body="true" @close="closes">
			<div class="diaBackup" style="height: 500px;display: flex;border-top: 1px solid #ccc;">
				<div class="diaBackup-nav"
					style="height: 100%;overflow-y: hidden;display: flex;flex-direction: column;min-width: 200px;border-right: 1px solid #ccc;">
					<el-checkbox :indeterminate="isInde" v-model="checkAllBack" @change="handleAllChange"
						style="margin: 10px 0;">全选
					</el-checkbox>
					<div style="height: 100%;overflow-y: auto;" @scroll="scrollNav">
						<el-checkbox-group v-model="checkedCities1" @change="handleCheckedChange">
							<el-checkbox v-for="item,index in BackNav" :label="item" :key="index"style="padding: 10px 0;">
								<div>{{item.T_name}}</div>
								<div>{{item.T_sn}}</div>
							</el-checkbox>
						</el-checkbox-group>
						<div v-show="BackPages.page == scrPage"
							style="font-size: 12px;color: #ccc;text-align: center;padding: 10px 0;border-top: .5px solid #ccc;">
							没有更多数据了哦
						</div>
					</div>
				</div>
				<div class="diaBackup-cont" style="flex: 1;margin-left: 10px;">
					<div style="padding: 10px 0;border-bottom: 1px solid #ccc;display: flex;align-items: center;">
						<p>备份时间：</p>
						<el-date-picker v-model="TimeRange" type="daterange" range-separator="至"
							:picker-options="picOption" @change="changTimerange" value-format='yyyy-MM-dd'
							:clearable="false"
							start-placeholder="开始日期" end-placeholder="结束日期">
						</el-date-picker>
					</div>
					<el-table :data="BacktableData" style="width: 100%">
						<el-table-column label="设备名称" prop="T_name">
						</el-table-column>
						<el-table-column label="数据量" prop="T_num">
						</el-table-column>
						<el-table-column label="备份时间" prop="T_date">
						</el-table-column>
						<el-table-column align="right">
							<template slot="header" slot-scope="scope">
								<el-button size="mini" @click="dowAllFun(scope)" :disabled="checkedCities1.length==0?true:false">全部下载</el-button>
							</template>
							<template slot-scope="scope">
								<el-button size="mini" @click="dowsingFun(scope.row)">下载</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination @current-change="handleSize"
						:current-page="BackUppage.page" :page-size="BackUppage.page_z"
						layout="total, prev, pager, next, jumper" :total="totalBack" style="margin-top: 50px;">
					</el-pagination>
				</div>
			</div>
		</el-dialog>
		<el-dialog title="备份阅览" :visible.sync="ShowBackDia" width="50%"
		 :show-close='percentage==100?true:false'
		 :close-on-press-escape='false'
		 :close-on-click-modal='false'
		 :append-to-body="true">
		 <div style="display: flex;align-items: flex-end;justify-content: center;">
		 	<div style="display: flex;align-items: center;justify-content: center;flex-direction: column;">
		 		<el-progress type="circle" :width='100' :percentage="100" :format="format">
		 		</el-progress>
		 		<div style="margin-top: 5px;color: #999;font-size: 12px;font-weight: 500;">备份总数</div>
		 	</div>
		 	<div style="display: flex;align-items: center;justify-content: center;flex-direction: column;">
		 		<el-progress type="dashboard" :width='150' :percentage="percentage" :format="formatall" :color="colors"></el-progress>
		 		<div style="margin-top: 5px;color: #999;font-size: 12px;font-weight: 500;">当前进度</div>
		 	</div>
		 	<div style="display: flex;align-items: center;justify-content: center;flex-direction: column;">
		 		<el-progress type="circle" :width='100' :percentage="100" :format="format1"></el-progress>
		 		<div style="margin-top: 5px;color: #999;font-size: 12px;font-weight: 500;">备份成功</div>
		 	</div>
		 </div>
		  <span slot="footer" style="display: flex;justify-content: center;">
		    <el-button type="primary" @click="ShowBackDia = false" v-show="percentage==100?true:false">我知道了</el-button>
		  </span>
		</el-dialog>
        <el-dialog
            title="是否显示湿度"
            append-to-body
            :visible.sync="dialogVisibles"
            width="30%">
            <el-switch
            v-model="pages1.T_ish"
            active-text="显示湿度"
            :active-value="1"
            :inactive-value="0"
            inactive-text="隐藏湿度">
            </el-switch>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisibles = false">取 消</el-button>
                <el-button type="primary" @click="subData">确 定</el-button>
            </span>
            </el-dialog>
	</div>
</template>

<script>
	import {
		ClassList,
		DevSenList,
		DataList,
		DataExcel,
		DataPDF,
		DataBackUp, //列表
		DataBackUpPDF //下载
	} from '../../api/dataDisplay/dataShow.js'
	import {
		formatDate,
		Timerange
	} from '../../utils/Times.js'
	import Axios from 'axios'
	import fileDownload from 'js-file-download';
	import {
		sniJoin 
	} from '@/utils/sinIDFun.js';
	export default {
		data() {
			return {
				a:1,
				b:3,
				colors: [{
						color: '#f56c6c',
						percentage: 20
					},
					{
						color: '#e6a23c',
						percentage: 40
					},
					{
						color: '#5cb87a',
						percentage: 60
					},
					{
						color: '#1989fa',
						percentage: 80
					},
					{
						color: '#67C23A',
						percentage: 100
					},
				],
                dialogVisibles:false,
				ShowBackDia:false,
				dataAll:0,
				datasucc:0,
				percentage: 0, //导入进度
				totalBack:0,
				picOption: {
					disabledDate(time) {
						let AtTime = new Date().getTime(); //获取此时的时间戳
						return (
							time.getTime() < AtTime - (86400000 * 40) //小于当前时间40天的全部disabled掉
							||
							time.getTime() > AtTime //大于当前时间的全部disabled掉
						);
					}
				},
				TimeRange: [
					Timerange(new Date().getTime() - (86400000 * 40)), Timerange(new Date())
				],
				BackNav: [],
				scrPage: 0, //总页码
				BacktableData: [],
				BackPages: {
					page: 1,
					page_z: 20,
				},
				BackUppage: {
					T_snid: '',
					Time_start: '',
					Time_end: '',
					page: 1,
					page_z: 5,
				},

				showTBLoad: false,
				hideSin: true,
				TimeValue: [
					new Date(new Date().toLocaleDateString()).getTime(), new Date()
				],
				total: 0,
				total1: 0,
				pagenum: 0,
				pages: {
					T_name: '',
					T_calssid: '',
					page: 1,
					page_z: 30,
				},
				Page_size: 0,
				pages1: { //表格数据
					T_snid: '',
					Time_start: '',
					Time_end: '',
					page: 1,
					page_z: 10,
                    T_ish:1
				},
				pickerOptions: {
					shortcuts: [{
						text: '今天',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(new Date(new Date().toLocaleDateString()).getTime());
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(new Date(new Date().setHours(0, 0, 0, 0)).getTime() - 86400000 * 6);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '近一月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(new Date(new Date().setHours(0, 0, 0, 0)).getTime() - 86400000 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '近一季度',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(new Date(new Date().setHours(0, 0, 0, 0)).getTime() - 86400000 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
				tableData: [],
				checkAll: false,
				checkAllBack: false,
				checkedCities: [], //已经选择的
				checkedCities1: [], //已经选择的
				cities: [],
				isIndeterminate: false,
				isInde: false,
				dialogVisible: false,
				diaBackupShow: false, //备份数据
				options: [],
			}
		},
		computed: {
			searchso() {
				return this.$store.state.searchs
			}
		},
		watch: {
			searchso(newVal) {
				this.dialogVisible = newVal
			},
			cities(newVal) {
				////console.log('变化', newVal)
				if (newVal.length == 0) {
					this.tableData = []
					this.checkedCities = []
					this.pages1.T_snid = ""
				}
			},
			checkedCities(newVal) {
				////console.log('*****************************87',newVal.length)
				this.pages1.T_snid = sniJoin(this.checkedCities)
				this.pages1.Time_start = formatDate(this.TimeValue[0])
				this.pages1.Time_end = formatDate(this.TimeValue[1])
				this.pages1.page = 1
				this.GetDataListApi()
			},
		},
		mounted() {
			////console.log('88',this.checkedCities,this.isIndeterminate)
			this.GetClassListApi()
			this.GetDevSenListApi()
		},
		methods: {
			load () {
				////console.log('页码', this.pages.page == this.Page_size,this.pages.page , this.Page_size)
				if(this.pages.page == this.Page_size){
					this.$message.info('哎呀，已经到底了')
					return
				}
			    this.pages.page = this.pages.page+1
				this.GetDevSenListApi()
				////console.log('触底', this.pages.page)
			},
			closes(){
				this.BacktableData = []
				this.totalBack = 0
				this.checkAllBack = false
				this.checkedCities1 = []
				this.TimeRange = [
					Timerange(new Date().getTime() - (86400000 * 40)), Timerange(new Date())
				]
			},
			formatall(){
				if(this.percentage!=100){
					return `${this.percentage}%`
				}else{
					return `备份完成`
				}
			},
			format() {
				return `${this.dataAll}条`
			},
			format1() {
				return `${this.datasucc}条`
			},
			changTimerange(e) { //时间选择
				var that = this
				this.BackUppage.page=1
				if (e != null) {
					this.BackUppage.Time_start = e[0]
					this.BackUppage.Time_end = e[1]
				} else {
					this.TimeRange = [Timerange(new Date().getTime() - (86400000 * 40)), Timerange(new Date())]
					this.$emit("pick",this.TimeRange)
					this.BackUppage.Time_start = Timerange(new Date().getTime() - (86400000 * 40))
					this.BackUppage.Time_end = Timerange(new Date())
				}
				if (this.checkedCities1.length > 0) {
					this.GetbackListApi()
				}
			},
			async scrollNav(e) { //滚动加载
				var that = this
				let isBottom = e.target.scrollTop + e.target.clientHeight == e.target.scrollHeight
				if (isBottom && this.BackPages.page < that.scrPage) {
					let height = e.target.scrollTop
					e.target.scrollTop = height
					this.BackPages.page = this.BackPages.page + 1
					var resIt = await this.GetBackPam()
					if (resIt.data.Code === 200) {
						that.BackNav = [...that.BackNav, ...resIt.data.Data.DeviceSensor_lite]
					}
				}
			},
			async backup() {
				this.diaBackupShow = true
				////console.log('时间', this.TimeRange)
				this.BackUppage.Time_start = this.TimeRange[0]
				this.BackUppage.Time_end = this.TimeRange[1]
				this.BackNav = []
				this.BackPages.page = 1
				var resIt = await this.GetBackPam()
				if (resIt.data.Code === 200) {
					this.BackNav = resIt.data.Data.DeviceSensor_lite
				}
			},
			GetBackPam() {
				var that = this
				return new Promise(resolve => {
					DevSenList(this.BackPages).then(function(res) {
						that.scrPage = res.data.Data.Page_size
						resolve(res);
					})
				});
			},
			Timechange(e) {
				////console.log('清空时间', e)
				if (e == null) {
					this.pages1.Time_start = ''
					this.pages1.Time_end = ''
					this.TimeValue = [
						'', ''
					]
				} else {
					this.pages1.Time_start = formatDate(e[0])
					this.pages1.Time_end = formatDate(e[1])
				}
				this.pages1.page = 1
				this.GetDataListApi()

			},
			GetDataListApi() {
				this.showTBLoad = true
				DataList(this.pages1).then(res => {
					if (res.data.Code === 200 && res.data.Data != null) {
						if (res.data.Data.Data != null) {
							////console.log('222222r',res)
							this.tableData = res.data.Data.Data
							this.total1 = res.data.Data.Num
						} else {
							this.tableData = []
							this.total1 = 0
						}
					} else {
						this.tableData = []
						this.total1 = 0
					}
					this.showTBLoad = false
				})
			},
			//左侧导航菜单
			async GetDevSenListApi() {
				var that = this
				var resIt = await this.GetDevSenPam()
				////console.log('===========', resIt)
				if (resIt.data.Code === 200) {
					var Arr = resIt.data.Data.DeviceSensor_lite
					this.total = resIt.data.Data.Num
					this.Page_size = resIt.data.Data.Page_size
					if (Arr != null) {
						that.cities = [...that.cities,...Arr]
					}else{
						that.cities = []
					}
					////console.log('数据', that.cities)
				}
			},
			GetDevSenPam() {
				return new Promise(resolve => {
					DevSenList(this.pages).then(function(res) {
						resolve(res);
					})
				});
			},
			//select列表
			GetClassListApi() {
				var that = this
				ClassList({}).then(res => {
					////console.log('select', res)
					if (res.data.Code === 200 && res.data.Msg === 'ok!') {
						var Arr = res.data.Data.Data
						Arr.forEach(function(item) {
							var obj = {
								value: '',
								label: ''
							}
							obj.value = item.Id
							obj.label = item.T_name
							that.options.push(obj)
						})
						that.options.unshift({
							value: '所有分类',
							label: '所有分类'
						})
					}
				})
			},
			inputserValue(e) {
				
				this.cities = []
				this.pages.T_name = e
				this.pages.page = 1
				this.GetDevSenListApi()
			},
			selectsValue(e) {
				this.pages.T_calssid = e
				this.pages.page = 1
				this.GetDevSenListApi()
				this.cities = []
			},
			handleSizeChange(e) { //每页多少条
				////console.log(e)
				this.pages1.page_z = e
				this.GetDataListApi()
			},
			handleCurrentChange(e) { //跳转页
				this.pages1.page = e
				this.GetDataListApi()
			},
			DowDataExcel() {
				////console.log('导出exc', this.pages1)
				this.pages1.Time_start = formatDate(this.TimeValue[0])
				this.pages1.Time_end = formatDate(this.TimeValue[1])
				if (this.pages1.T_snid == "") {
					this.$message.error('请选择设备在导出数据')
					return
				} else {
					const loading = this.$loading({
						lock: true,
						text: '正在整理数据，请稍后...',
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});
					DataExcel(this.pages1).then(res => {
						////console.log('导出exc', res)
						var arr1 = res.data.Data.split('/')
						loading.close()
						Axios({
							method: 'get',
							url: res.data.Data,
							responseType: 'blob'
						}).then(resBlob => {
							fileDownload(resBlob.data, arr1[arr1.length-1]);
						});
					})
				}
			},

			DowDataPDF() {
				this.pages1.Time_start = formatDate(this.TimeValue[0])
				this.pages1.Time_end = formatDate(this.TimeValue[1])
				if (this.pages1.T_snid == "") {
					this.$message.error('请选择设备在导出数据')
				} else {
                    this.dialogVisibles = true
                    this.pages1.T_ish = 1
				}
			},
            subData(){
                const loading = this.$loading({
                    lock: true,
                    text: '正在整理数据，请稍后...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                DataPDF(this.pages1).then(res => {
                    ////console.log('下载',res)
                    var arr1 = res.data.Data.split('/')
                    loading.close()
                    Axios({
                        method: 'get',
                        url: res.data.Data,
                        responseType: 'blob'
                    }).then(resBlob => {
                        const FileSaver = require('file-saver')
                        const blob = new Blob([resBlob.data], {
                            type: 'application/pdf;charset=utf-8'
                        }) // 此处type根据你想要的
                        FileSaver.saveAs(blob, arr1[arr1.length-1]) // 下载的name文件名
                    });
                })
            },
			handleCheckAllChange(val) { //全选
				this.pages1.page = 1
				this.checkedCities = val ? this.cities : [];
				////console.log('1', val, this.checkedCities)
				this.isIndeterminate = false;
				
			},
			handleCheckedCitiesChange(value) { //单选
				this.pages1.page = 1
				////console.log('单点',this.pages1.page)
				let checkedCount = value.length;
				this.checkAll = checkedCount === this.cities.length;
				this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
				this.pages1.Time_start = formatDate(this.TimeValue[0])
				this.pages1.Time_end = formatDate(this.TimeValue[1])
				
			},
			theChange(val) { //反选
				let cities = this.cities;
				let checkedCities = this.checkedCities; //已经选择
				this.pages1.page = 1
				this.pages1.Time_start = formatDate(this.TimeValue[0])
				this.pages1.Time_end = formatDate(this.TimeValue[1])
				if (checkedCities.length === 0) {
					checkedCities = val ? cities : [];
				} else if (checkedCities.length === cities.length) {
					this.checkedCities = [];
					this.checkAll = false;
				} else {
					let list = cities.concat(checkedCities).filter((v, i, array) => {
						return array.indexOf(v) === array.lastIndexOf(v);
					});
					this.checkedCities = list;
				}
			},

			handleAllChange(val) { //备份全选
				////console.log('备份全选',this.BackUppage)
				this.BackUppage.page = 1
				this.checkedCities1 = val ? this.BackNav : [];
				this.isInde = false;
				this.BackUppage.T_snid = sniJoin(this.checkedCities1)
				if (this.BackUppage.T_snid != '') {
					this.GetbackListApi()
				} else {
					this.BacktableData = []
					this.totalBack = 0
				}
			},
			handleCheckedChange(value) { //备份单选
				////console.log('备份单选',this.BackUppage)
				this.BackUppage.page = 1
				let checkedCount = value.length;
				this.checkAllBack = checkedCount === this.BackNav.length;
				this.isInde = checkedCount > 0 && checkedCount < this.BackNav.length;
				this.BackUppage.T_snid = sniJoin(this.checkedCities1)
				if (this.BackUppage.T_snid != '') {
					this.GetbackListApi()
				} else {
					this.BacktableData = []
					this.totalBack = 0
				}
			},
			GetbackListApi() {
				DataBackUp(this.BackUppage).then(res => {
					////console.log('列表ss', res)
					if (res.data.Code === 200) {
						this.BacktableData = res.data.Data.Data
						this.totalBack = res.data.Data.Num
					}
				})
			},
			handleSize(e){
				this.BackUppage.page = e
				this.GetbackListApi()
			},
			async dowAllFun() { //全部下载
				this.ShowBackDia = true
				this.dataAll = 0
				this.datasucc = 0
				this.percentage = 0 //导入进度
				var obj = {...this.BackUppage} 
				obj.page_z = 999999
				var arr = []
				var resIt = await this.AllListApi(obj)
				console.log('第一个',resIt)
				if(resIt.data.Code==200){
					arr = resIt.data.Data.Data
					this.dataAll = resIt.data.Data.Num
				}
				for (let i = 0; i < arr.length; i++) {
					var resIt = await this.dowupPdf(arr[i])
				console.log('第2个',resIt)

					////console.log('全部下载',resIt)
					let arr1 = resIt.data.Data.split('/')
					////console.log('全部下载',arr1,arr1[arr1.length-1])
					if (resIt.data.Code === 200) {
						Axios({
							method: 'get',
							url: resIt.data.Data,
							responseType: 'blob'
						}).then(resBlob => {
							if(resBlob.status===200){
								this.datasucc = this.datasucc+1
							}
							this.percentage = parseFloat(Number((this.datasucc / this.dataAll) * 100)).toFixed(0) - 0
							const FileSaver = require('file-saver')
							const blob = new Blob([resBlob.data], {
								type: 'application/pdf;charset=utf-8'
							}) // 此处type根据你想要的
							FileSaver.saveAs(blob, arr1[arr1.length-1]) // 下载的name文件名
						});
					}
				}
			},
			AllListApi(obj) {
				return new Promise(resolve=>{
					DataBackUp(obj).then(res => {
						resolve(res)
					})
				})
			},
			async dowsingFun(e) { //单个下载
				const loading = this.$loading({
					lock: true,
					text: '正在整理数据，请稍后...',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				var resIt = await this.dowupPdf(e)
				let arr = resIt.data.Data.split('/')
				if (resIt.data.Code === 200) {
					Axios({
						method: 'get',
						url: resIt.data.Data,
						responseType: 'blob'
					}).then(resBlob => {
						const FileSaver = require('file-saver')
						const blob = new Blob([resBlob.data], {
							type: 'application/pdf;charset=utf-8'
						}) // 此处type根据你想要的
						FileSaver.saveAs(blob, arr[arr.length-1]) // 下载的name文件名
					});
				}
				loading.close()
			},
			dowupPdf(obj) {
				return new Promise(resolve => {
					DataBackUpPDF({
						T_sn: obj.T_sn,
						T_id: obj.T_id,
						T_date: obj.T_date,
					}).then(res => {
						setTimeout(function(){
							resolve(res)
						},1000)
					})
				})
			}
		},

	}
</script>

<style lang="scss">
	@import '../../assets/css/dataDisplay/dataShow.scss';
</style>
<style lang="scss" scoped>
	.errColor {
		color: red;
	}
	.sucColor {
		color: blue;
	}

	.el-table .warning-row {
		background: oldlace;
	}

	.el-table .success-row {
		background: #f0f9eb;
	}

	.el-checkbox {
		display: flex;
		align-items: center;
		flex: 1;
		margin-right: 0;
	}
</style>

<template>
    <div class="">
        <div id="containers" ref="mapContainers" style="width:100%;height:100vh;"></div>
        <!-- v-if="radios!=0 && Array.isArray(lineArr)?lineArr.length!=0:false" -->
        <div class="input-card" :style="{'opacity':showCar}" v-if="radios!=0 && AnimationPath.name">
            <el-button style="margin-left:0px" round @click="startAnimation()">开始播放</el-button>
            <el-button style="margin-left:0px" round @click="pauseAnimation()">暂停播放</el-button>
            <el-button style="margin-left:0px" round @click="resumeAnimation()">继续播放</el-button>
            <el-button style="margin-left:0px" round @click="stopAnimation()">停止轨迹</el-button>
        </div>
    </div>
</template>

<script>
import {baseURL} from '@/utils/base.js'
// 起点Icon
const startIcon = new AMap.Icon({
    // 图标尺寸
    size: new AMap.Size(25, 34),
    // 图标的取图地址
    image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
    // 图标所用图片大小
    imageSize: new AMap.Size(135, 40),
    // 图标取图偏移量
    imageOffset: new AMap.Pixel(-9, -3)
});
// 终点Icon
const endIcon = 'https://a.amap.com/jsapi_demos/static/demo-center-v2/car.png';

export default {
    props: {
        showCar: {
            type: Number,
            default: () => 1
        },
        MapList: {
            type: Array,
            default: () => []
        },
        selectData: {
            type: Array,
            default: () => []
        },
        radios: {
            type: Number,
            default: () => 0
        }
    },
    data() {
        return {
            map: null,
            polyline: null,
            center: [0,0], // 中心点
            socket: null,
            
            AnimationPath:{},//轨迹回放的路径
            polylines:{},
            startMarkers:{},
            endMarkers:{},
            infoWindows:{},

            markers: [],
            eventSource:[],//sse
        };
    },
    watch: {
        radios(newVal){
            if (newVal != '0') {
                this.clearsseAll()
            }
        }
    },
    mounted() {
        this.initMap();
    },
    methods: {
        initSSE(sn,id) {
            let self = this
            this.eventSource[sn] = new EventSource(`${baseURL}v3/Data/GetNewLocusSSe?sn=${sn}&t_id=${id}`); // 替换为你的SSE端点
            //连接成功
            this.eventSource[sn].onopen = (event) => {
                console.log('连接成功',event)
            };
            //接收消息
            this.eventSource[sn].onmessage = (event) => {
                let res = JSON.parse(event.data)
                console.log('接收消息',res)
                let arr =  res.data.reverse() || []
                let mapData = {
                    name: res.sn,
                    path : []
                }
                if(arr.length!= 0) {
                    arr.forEach(item => {
                        let [x, y] = item.T_site.split(',')
                        let j = [Number(x),Number(y),item.t_name, item.T_t, item.T_rh, item.t_time,item.T_ist,item.T_ish]
                        mapData.path.push(j)
                    })
                }
                let flag = Object.keys(self.polylines).includes(mapData.name);

                if (flag) {//已存在，更新
                    self.updateline(mapData)
                }else{//不存在，添加
                    self.addline(mapData);
                }
            };
            //连接错误
            this.eventSource[sn].onerror = (error) => {
                this.clearsseAll()
            };
        },
        //断开单个sse
        initSSEclose(sn) {
            this.eventSource[sn].close();
        },
       //断开see所有
        clearsseAll() {
            let self = this
            let arrs = {...self.polylines}
            Object.keys(arrs).forEach(sn => {
                self.removePolyline(sn); // 清空地图上的标记点
                if (self.eventSource[sn]) {
                    // 设置一个标志，表明 EventSource 已经关闭
                    self.eventSource[sn].closed = true;
                    self.eventSource[sn].close();
                    self.eventSource[sn] = null; // 确保不会再次使用该对象
                }
            });
        },

        // 初始化地图
        initMap() {
            let self = this
            // JSAPI2.0 使用覆盖物动画必须先加载动画插件
            AMap.plugin('AMap.MoveAnimation', function () {
                self.map = new AMap.Map("containers", {
                    resizeEnable: true,
                    center: self.center,//[116.397428, 39.90923],
                    zoom: 1,
                    WebGLParams: {
                        preserveDrawingBuffer: true
                    },
                });
            });
        },
        newMarker() {
            let self = this
            if (this.map) {
                this.map.setZoom(5);
                this.map.clearMap(); // 清除地图上的所有覆盖物（可选）
            }
            // 清除marker
            // if (this.endMarkers){
            //     this.stopAnimation()//停止动画
            // }
            self.map.setFitView();
            return;
            // 清除marker
            if (this.markers){
                this.stopAnimation()//停止动画
                this.markers = [];
            }
            // 清除旧轨迹（如果存在）
            if (this.polylines){
                this.polylines = [];
            }

            if (self.lineArr==null) {
                this.map.setZoom(5);
                return;
            }
            // if (self.lineArr.length == 0) {
            //     // this.$message.error('暂无可展示轨迹哦!')
            //     this.map.setZoom(5);
            //     return;
            // }
            this.map.setZoom(17);
            self.lineArr.forEach(function(item,index) {
                // 底部绘制轨迹
                let polyline  = new AMap.Polyline({
                    map: self.map,// 将标记添加到地图上
                    path: item.path,
                    showDir: true,
                    strokeColor: self.rgbColor(),  //线颜色
                    // strokeOpacity: 1,     //线透明度
                    strokeWeight: 6,      //线宽
                    // strokeStyle: "solid"  //线样式
                });
                self.polylines.push(polyline);

                //绘制起点
                var startMarker = new AMap.Marker({
                    id: item.name,
                    map: self.map,// 将标记添加到地图上
                    position:item.path[0],
                    icon:startIcon,
                    offset: new AMap.Pixel(-13, -32),
                });
                //绘制终点
                let endMarker = new AMap.Marker({
                    id: item.name,
                    map: self.map,
                    position: item.path[item.path.length - 1],
                    icon: endIcon, // 自定义终点图标路径
                    offset: new AMap.Pixel(-13, -26),
                });

                self.markers.push(endMarker,startMarker);

                // 创建内容弹窗
                let mokerData = item.path[item.path.length - 1];
                let infoWindow = new AMap.InfoWindow({
                        content:mokerData[6]==2 && mokerData[7]==1?
                            `<div style="padding:10px;color:#000;font-size:12px;line-height: 20px;">
                                <div style="font-weight: bold;font-size:12px;">${mokerData[2]}<div>
                                <div style="font-weight: 100;font-size:12px;">湿度：${mokerData[4]}%<div>
                                <div style="font-weight: 100;font-size:12px;">时间：${mokerData[5]}<div>
                            </div>`
                            : mokerData[6]==1 && mokerData[7]==2?`<div style="padding:10px;color:#000;font-size:12px;line-height: 20px;">
                                <div style="font-weight: bold;font-size:12px;">${mokerData[2]}<div>
                                <div style="font-weight: 100;font-size:12px;">温度：${mokerData[3]}°C<div>
                                <div style="font-weight: 100;font-size:12px;">时间：${mokerData[5]}<div>
                            </div>`
                            :mokerData[6]==2 && mokerData[7]==2?`<div style="padding:10px;color:#000;font-size:12px;line-height: 20px;">
                                <div style="font-weight: bold;font-size:12px;">${mokerData[2]}<div>
                                <div style="font-weight: 100;font-size:12px;">时间：${mokerData[5]}<div>
                            </div>`
                            :`<div style="padding:10px;color:#000;font-size:12px;line-height: 20px;">
                                <div style="font-weight: bold;font-size:12px;">${mokerData[2]}<div>
                                <div style="font-weight: 100;font-size:12px;">温度：${mokerData[3]}°C<div>
                                <div style="font-weight: 100;font-size:12px;">湿度：${mokerData[4]}%<div>
                                <div style="font-weight: 100;font-size:12px;">时间：${mokerData[5]}<div>
                            </div>`, // 你可以在这里放置HTML内容
                        size: new AMap.Size(200, 100), // 弹窗的宽高
                        autoMove: true, // 是否自动移动到视野内
                        offset: new AMap.Pixel(-20, -50), // 弹窗相对于标记的偏移量
                        closeWhenClickMap: true // 是否点击地图关闭
                }); 
                self.infoWindows.push(infoWindow);
                
                // 添加点击事件监听器到标记
                endMarker.on('click', function(e) {
                    self.infoWindows.forEach(Windows => {
                        Windows.open(self.map, item.path[item.path.length-1]); // 在点击的位置打开弹窗
                    })
                    
                });

                
                // self.map.add(polyline);
                // self.polylines.push(polyline);
                //行走的轨迹
                // var passedPolyline = new AMap.Polyline({
                //     map: self.map,
                //     strokeColor: "red",  //线颜色
                //     strokeWeight: 6,      //线宽
                // });
                // marker.on('moving', function (e) {
                //     // passedPolyline.setPath(e.passedPath);
                //     self.map.setCenter(e.target.getPosition(), false)//跟着中心点
                // });
            })
            self.map.setFitView();
        },
        /**
         * 添加一条轨迹
         * @param obj  {name:sn,path:[[lon，lat],[lon，lat]]}
         */
        addline(obj) {
            this.AnimationPath = obj//当作轨迹回放的路径
            let self = this
            console.log('添加一条',self.polylines,obj)
            if(obj.path.length == 0) {
                // this.$message.error('暂无轨迹数据')
                return;
            }
            // 底部绘制轨迹
            self.polylines[obj.name]  = new AMap.Polyline({
                map: self.map,// 将标记添加到地图上
                path: obj.path,
                showDir: true,
                strokeColor: self.rgbColor(),  //线颜色
                // strokeOpacity: 1,     //线透明度
                strokeWeight: 6,      //线宽
                // strokeStyle: "solid"  //线样式
            });

            //绘制起点
            self.startMarkers[obj.name] = new AMap.Marker({
                id: obj.name,
                map: self.map,// 将标记添加到地图上
                position:obj.path[0],
                icon:startIcon,
                offset: new AMap.Pixel(-13, -32),
            });

            //绘制终点
            self.endMarkers[obj.name] = new AMap.Marker({
                id: obj.name,
                map: self.map,
                position: obj.path[obj.path.length - 1],
                icon: endIcon, // 自定义终点图标路径
                offset: new AMap.Pixel(-13, -26),
            });

            // 创建内容弹窗
            let mokerData = obj.path[obj.path.length - 1];
            self.infoWindows[obj.name] = new AMap.InfoWindow({
                    content:mokerData[6]==2 && mokerData[7]==1?
                        `<div style="padding:10px;color:#000;font-size:12px;line-height: 20px;">
                            <div style="font-weight: bold;font-size:12px;">${mokerData[2]}<div>
                            <div style="font-weight: 100;font-size:12px;">湿度：${mokerData[4]}%<div>
                            <div style="font-weight: 100;font-size:12px;">时间：${mokerData[5]}<div>
                        </div>`
                        : mokerData[6]==1 && mokerData[7]==2?`<div style="padding:10px;color:#000;font-size:12px;line-height: 20px;">
                            <div style="font-weight: bold;font-size:12px;">${mokerData[2]}<div>
                            <div style="font-weight: 100;font-size:12px;">温度：${mokerData[3]}°C<div>
                            <div style="font-weight: 100;font-size:12px;">时间：${mokerData[5]}<div>
                        </div>`
                        :mokerData[6]==2 && mokerData[7]==2?`<div style="padding:10px;color:#000;font-size:12px;line-height: 20px;">
                            <div style="font-weight: bold;font-size:12px;">${mokerData[2]}<div>
                            <div style="font-weight: 100;font-size:12px;">时间：${mokerData[5]}<div>
                        </div>`
                        :`<div style="padding:10px;color:#000;font-size:12px;line-height: 20px;">
                            <div style="font-weight: bold;font-size:12px;">${mokerData[2]}<div>
                            <div style="font-weight: 100;font-size:12px;">温度：${mokerData[3]}°C<div>
                            <div style="font-weight: 100;font-size:12px;">湿度：${mokerData[4]}%<div>
                            <div style="font-weight: 100;font-size:12px;">时间：${mokerData[5]}<div>
                        </div>`, // 你可以在这里放置HTML内容
                    size: new AMap.Size(200, 100), // 弹窗的宽高
                    autoMove: true, // 是否自动移动到视野内
                    offset: new AMap.Pixel(-10, -50), // 弹窗相对于标记的偏移量
                    closeWhenClickMap: true // 是否点击地图关闭
            }); 
            // 添加点击事件监听器到标记
            self.endMarkers[obj.name].on('click', function(e) {
                self.infoWindows[obj.name].open(self.map, obj.path[obj.path.length-1]); // 在点击的位置打开弹窗
            });
            // self.endMarkers[obj.name].moveAlong(obj.path.slice(-2), {
            //     duration: 1000, // 动画持续时间（毫秒）
            //     autoRotation: true // 是否自动旋转
            // });
            self.map.setFitView();
        },

        /**
         * 从地图上移除轨迹
         * @param name 对应sn
         */
        removePolyline(name) {
            if(!this.polylines[name]) return;
            // 从地图上移除轨迹
            this.polylines[name].setMap(null);
            delete this.polylines[name];

            this.startMarkers[name].setMap(null);
            delete this.startMarkers[name];

            this.endMarkers[name].setMap(null);
            delete this.endMarkers[name];

            this.infoWindows[name].setMap(null);
            delete this.infoWindows[name];
        },
        
        /**
         * 更新某一条轨迹
         * @param key 对应sn
         * @param newPath 二维数组[[lon，lat],[lon，lat]]
         */
        updateline(newPath) {
            console.log('更新一条',newPath.name)
            if(newPath.path.length == 0) {
                return;
            }
            this.polylines[newPath.name].setPath(newPath.path);//更新轨迹
            // this.endMarkers[newPath.name].moveAlong(newPath.path.slice(-2), {
            //     duration: 1000, // 动画持续时间（毫秒）
            //     autoRotation: true // 是否自动旋转
            // });
            this.map.setFitView();
        },

        rgbColor() {
            // 生成一个0到15之间的随机数，并将其转换为十六进制格式（0-9, a-f）
            const r = Math.floor(Math.random() * 256).toString(16).padStart(2, '0');
            const g = Math.floor(Math.random() * 256).toString(16).padStart(2, '0');
            const b = Math.floor(Math.random() * 256).toString(16).padStart(2, '0');
            
            // 将红、绿、蓝三个分量拼接成一个完整的十六进制颜色代码
            return `#${r}${g}${b}`;
        },
        //开始回放
        startAnimation() {
            //清除已经点开的弹窗
            this.infoWindows[this.AnimationPath.name].close()
            this.endMarkers[this.AnimationPath.name].moveAlong(this.AnimationPath.path, {
                duration: 500,//每一段的时长
                autoRotation: true,// JSAPI2.0 是否延道路自动设置角度在 moveAlong 里设置
            })
        },
        //暂停动画
        pauseAnimation () {
            this.endMarkers[this.AnimationPath.name].pauseMove()
        },
        //继续动画
        resumeAnimation () {
            this.endMarkers[this.AnimationPath.name].resumeMove()
        },
        // 停止动画
        stopAnimation () {
           this.endMarkers[this.AnimationPath.name].stopMove()
        },

    },
    beforeDestroy() {
        console.log('断开移除')
        this.clearsseAll()
    }

}

</script>
<style lang="scss">
.input-card {
    position: absolute;
    right: 340px;
    bottom: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    background: #fff;
    padding: 10px;
    box-shadow: 0 2px 2px rgb(0 0 0 / 15%);
    border-radius: 4px;
    border: .5px solid rgba(#DCDFE6, 1);
}
</style>
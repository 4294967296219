<template>
    <div class="Company">
        <div class="Company-T">
            <div class="CompanyL">
                <Breadcrumb></Breadcrumb>
                <div class="RightsManagement-searchs">
                    <div class="RightsManagement-searchs1">
                        <inputs @inputs="inputValue" :inputShow="true" inputPlace="请输入名称"></inputs>
                    </div>
                    <div class="Companyit-mai1-btn">
                        <!-- <el-button type="danger" icon="el-icon-search">查询</el-button> -->
                    </div>
                </div>
            </div>
            <div class="CompanyR">
                <div class="RightsManagement-searchsMai">
                    <!-- <el-button type="danger" icon="el-icon-plus" @click="clickNew()">新增</el-button> -->
                    <el-button type="primary" v-permission="'Company:Add'" @click="clickNew()"
                        icon="el-icon-plus">新增公司</el-button>
                </div>
            </div>
        </div>
        <div class="Company-B" style="overflow-y: hidden;">
            <el-table :data="tableData"
            v-loading="loading"
            style="width: 100%;margin-bottom: 20px;" row-key="Id" border
                :tree-props="{ children: 'Children', hasChildren: 'hasChildren' }" height="100%">
                <template slot="empty">
                    <noData></noData>
                </template>
                <el-table-column label="公司名称" width="450">
                    <template slot-scope="scope">
                        {{ scope.row.T_name }}[{{ scope.row.Id }}]
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" min-width="700">
                    <template slot-scope="scope">
                        <el-button size="small" v-permission="'Company:Visit'" @click="Gohome(scope.row)">进入</el-button>
                        <el-button size="small" v-permission="'Company:Filiale'"
                            @click="SetAdd(scope.row)">添加分公司</el-button>
                        <el-button size="small" v-permission="'Company:Edit'" @click="SetEdit(scope.row)">编辑</el-button>

                        <el-button size="small" v-permission="'Company:Filiale'"
                            @click="nubDigital(scope.row)">数字孪生</el-button>
                        <el-button size="small" v-permission="'Company:Edit'"
                            @click="statistics(scope.row)">报警统计</el-button>
                        <el-button size="small" v-permission="'Company:Filiale'"
                            @click="upTop(scope.row)">充值</el-button>
                        <el-button size="small" v-permission="'Company:Charging'"
                            @click="showCharge(scope.row)">记账扣费</el-button>

                        <el-button size="small" v-permission="'Company:Del'" @click="SetDel(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- <div style="margin-top: 50px;">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
					:current-page="pages.page" :page-size="pages.page_z"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div> -->
        </div>

        <el-dialog :title="titles" :visible.sync="dialogVisible" :append-to-body="true">
            <div class="NewUser">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="公司ID" v-if="titles == '编辑公司'">
                        <el-input v-model="ruleForm.T_mid" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="公司名称" prop="T_name">
                        <el-input v-model="ruleForm.T_name" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="公司密钥" v-if="titles == '编辑公司'">
                        <el-input v-model="T_key" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="公司类型">
                        <el-radio-group v-model="ruleForm.T_type">
                            <el-radio :label="1">医药</el-radio>
                            <el-radio :label="2">运输</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="坐标" prop="T_coordinate">
                        <el-input v-model="ruleForm.T_coordinate" @focus="setMaps" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="地址" prop="T_Address">
                        <el-input v-model="ruleForm.T_Address" @focus="setMaps" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('ruleForm')">立即提交</el-button>
                        <el-button @click="dialogVisible = false">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <el-dialog title="坐标点" :visible.sync="innterVisible" width="80%" height="90%" :append-to-body="true">
                <div id="container" style="width: 100%;min-height: 600px;"></div>
                <div class="input-cards">
                    <el-input v-model="ruleForm.T_coordinate" placeholder="经纬度"></el-input>
                    <el-input id="inputEnter" v-model="ruleForm.T_Address" placeholder="地址"></el-input>
                </div>
            </el-dialog>
        </el-dialog>
        <el-dialog title="数据孪生" :visible.sync="diaTwin" :append-to-body="true">
            <div class="NewUser">
                <el-form :model="ruleFormTwin" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="智慧大屏">
                        <el-input v-model="ruleFormTwin.T_data"></el-input>
                    </el-form-item>
                    <el-form-item label="平面图">
                        <el-input v-model="ruleFormTwin.T_plan"></el-input>
                    </el-form-item>
                    <el-form-item label="3D全景大屏">
                        <el-input v-model="ruleFormTwin.T_v3d"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitFormTwin">立即提交</el-button>
                        <el-button @click="diaTwin = false">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>
        <el-dialog title="记账扣费" :visible.sync="diaCharge" :append-to-body="true">
            <div class="NewUser">
                <el-form :model="ruleFormTwin" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="记账扣费公司" p>
                        <el-cascader v-model="cascaderValue" style="width: 100%;" :options="tableCascader"
                            :props="{ children: 'Children', checkStrictly: true, label: 'T_name', value: 'Id', emitPath: false, checkStrictly: true }"
                            clearable></el-cascader>
                    </el-form-item>
                    <!-- <el-form-item label="记账扣费公司">
						<el-select v-model="cascaderValue" placeholder="请选择">
							<el-option :value="cascaderValue" class="setstyle" disabled>
								<el-tree :data="tableData" :props="{ children: 'Children', label: 'T_name' }" ref="tree" show-checkbox check-strictly :expand-on-click-node="false" node-key="Id" check-on-click-node
									:tree-props="{ children: 'Children', hasChildren: 'hasChildren', label: 'T_name' }"></el-tree>
							</el-option>
						</el-select>
					</el-form-item> -->
                    <el-form-item>
                        <el-button type="primary" @click="subcascaderFun">立即提交</el-button>
                        <el-button @click="diaCharge = false">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>
        <el-dialog title="报警统计" :visible.sync="diaAlarm" :append-to-body="true">
            <div class="NewUser">
                <el-form :model="ruleWarning" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="是否统计">
                        <el-switch v-model="ruleWarning.T_warning" :active-value="1" :inactive-value="2"></el-switch>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitWin()">立即提交</el-button>
                        <el-button @click="diaAlarm = false">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    CompanyTree,
    CompanyDel,
    CompanyEdit,
    CompanyAdd,
    CompanyEntry,
    dataTwin,
    dataPay, dataWarning, dataCharging
} from '@/api/companyManagement/Company.js'
import externalObj from "@/plugins/tree.js";
export default {
    data() {
        return {
            loading:true,
            map: null,
            autocomplete: null,
            placeSearch: null,
            geocoder: null,

            innterVisible: false,
            cascaderid: null,
            cascaderValue: null,
            tableCascader: [],
            total: 0,
            pages: {
                T_name: '',
                page: 1,
                page_z: 999999,
            },
            T_key: '',
            ruleForm: {//添加公司时T_mid可不传，添加分公司时传当前公司id
                T_name: '',
                T_mid: '',
                T_type: 1,
                T_Address: '',//地址
                T_coordinate: '',//经纬度
            },
            ruleFormTwin: {//数据孪生
                Id: '',
                T_data: '',
                T_plan: '',
                T_v3d: '',
            },
            ruleWarning: {//数据孪生
                Id: '',
                T_warning: ''
            },
            condition: '',//区分点的时编辑Edit还是新增add
            tableData: [],
            titles: '',
            dialogVisible: false,
            diaTwin: false,//数据孪生
            diaAlarm: false,//报警统计
            diaCharge: false,//记账扣费
            rules: {
                T_name: [{
                    required: true,
                    message: '请输入名称公司名称',
                    trigger: 'change'
                }],
                T_Address: [{//地址
                    required: true,
                    message: '请选择地址',
                    trigger: 'change'
                }],
                T_coordinate: [{//经纬度
                    required: true,
                    message: '请选择坐标',
                    trigger: 'change'
                }],
            }
        }
    },
    mounted() {
        this.GetCompanyTreeApi()
    },
    methods: {
        setMaps() {
            this.innterVisible = true
            this.$nextTick(() => {
                this.initMap();
            })
        },
        initMap() {
            let self = this
            var map = new AMap.Map("container", {
                resizeEnable: true
            });
            
            var geocoder = new AMap.Geocoder({
                city: "", //城市设为北京，默认：“全国”
                radius: 1000 //范围，默认：500
            });
            var marker = new AMap.Marker();;
            //坐标转地址
            function regeoCode() {
                var lnglat = self.ruleForm.T_coordinate;
                map.add(marker);
                marker.setPosition(lnglat);
                self.ruleForm.T_coordinate = lnglat.lng + ',' + lnglat.lat
                geocoder.getAddress(lnglat, function (status, result) {
                    if (status === 'complete' && result.regeocode) {
                        self.ruleForm.T_Address = result.regeocode.formattedAddress;
                    } else {
                        log.error('根据经纬度查询地址失败')
                    }
                });
            }
            //地址转坐标
            function geoCode() {
                var address  = self.ruleForm.T_Address;//获取传入经纬度
                geocoder.getLocation(address, function(status, result) {
                    if (status === 'complete'&&result.geocodes.length) {
                        var lnglat = result.geocodes[0].location
                        self.ruleForm.T_coordinate = `${lnglat.lng},${lnglat.lat}`;
                        marker.setPosition(lnglat);
                        map.add(marker);
                        map.setFitView(marker);
                    }else{
                        console.error('根据地址查询位置失败');
                    }
                });
            }

            document.getElementById('inputEnter').onkeydown = function(e) {
                if (e.key == 'Enter') {
                    geoCode();
                    return false;
                }
                return true;
            };

            map.on('click', function (e) {
                self.ruleForm.T_coordinate = e.lnglat;//点击赋值经纬度
                regeoCode();
            })

            if(self.titles=='编辑公司'){
                geoCode()
            }
        },

        subcascaderFun() {
            dataCharging({
                Id: this.cascaderid,
                T_Charging: this.cascaderValue == null ? 0 : this.cascaderValue
            }).then(res => {
                if (res.data.Code == 200) {
                    this.$message.success('ok，操作成功！')
                    this.diaCharge = false
                    this.GetCompanyTreeApi()
                }
            })
        },
        showCharge(e) {

            this.tableCascader = [externalObj, ...this.tableData]
            console.log('e', this.tableCascader)
            this.cascaderid = e.Id
            this.cascaderValue = e.T_Charging
            this.diaCharge = true
        },
        statistics(e) {//报警统计
            ////console.log('充值',e)
            this.ruleWarning.Id = e.Id
            this.ruleWarning.T_warning = e.T_warning
            this.diaAlarm = true
        },
        submitWin() {
            dataWarning(this.ruleWarning).then(res => {
                if (res.data.Code == 200) {
                    this.$message.success('报警统计设置成功')
                    this.GetCompanyTreeApi()
                    this.diaAlarm = false
                    Object.keys(this.ruleWarning).forEach(key => { this.ruleWarning[key] = '' })
                }
            })
        },
        upTop(e) {//充值
            ////console.log('充值',e)
            this.$prompt('请输入充值金额', '提示', {
                confirmButtonText: '立即充值',
                cancelButtonText: '取消',
                inputPattern: /^[0-9]+(.[0-9]{1,2})?$/,
                inputErrorMessage: '充值金额不正确噢！'
            }).then(({ value }) => {
                dataPay({
                    Id: e.Id,
                    T_balance: value
                }).then(res => {
                    ////console.log('操作成功', res)
                    if (res.data.Code == 200) {
                        this.$message.success('充值成功')
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消充值'
                });
            });
        },
        nubDigital(e) {
            this.diaTwin = true
            this.ruleFormTwin.Id = e.Id
            this.ruleFormTwin.T_data = e.T_data
            this.ruleFormTwin.T_plan = e.T_plan
            this.ruleFormTwin.T_v3d = e.T_v3d
        },
        submitFormTwin() {//提交数据孪生
            ////console.log('提交', this.ruleFormTwin)
            dataTwin(this.ruleFormTwin).then(res => {
                ////console.log('操作成功', res)
                if (res.data.Code == 200) {
                    this.$message.success('数据孪生操作成功')
                    this.diaTwin = false
                    this.GetCompanyTreeApi()
                    Object.keys(this.ruleFormTwin).forEach(key => {
                        this.ruleFormTwin[key] = ''
                    })
                }
            })
        },
        GetCompanyTreeApi() {//列表树
            this.loading = true
            let self = this
            CompanyTree(this.pages).then(res => {
                if (res.data.Code === 200) {
                    const { Data: resIt } = res.data
                    this.tableData = resIt.Data == null ? [] : resIt.Data
                    this.total = resIt.Num
                }
                setTimeout(() => {
                    self.loading = false
                }, 200);
            })
        },
        SetCompanyAddApi() {//添加
            CompanyAdd(this.ruleForm).then(res => {
                ////console.log('添加', res)
                if (res.data.Code === 200) {
                    this.dialogVisible = false
                    this.$message.success('操作成功，已添加新公司')
                    this.GetCompanyTreeApi()
                }
            })
        },
        SetCompanyEditApi() {//编辑
            var obj = {
                Id: this.ruleForm.T_mid,
                T_name: this.ruleForm.T_name,
                T_type: this.ruleForm.T_type,
                T_Address: this.ruleForm.T_Address,
                T_coordinate: this.ruleForm.T_coordinate,
            }
            CompanyEdit(obj).then(res => {
                ////console.log('编辑', res)
                if (res.data.Code === 200) {
                    this.dialogVisible = false
                    this.$message.success('操作成功，已完成编辑')
                    this.GetCompanyTreeApi()
                }
            })
        },


        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.condition == 'add') {//新增
                        this.SetCompanyAddApi()
                    } else {//编辑
                        this.SetCompanyEditApi()
                    }
                } else {
                    return false;
                }
            });
        },
        SetAdd(e) { //添加分公司
            if (this.$refs.ruleForm) { //为了验证第一次触发表单验证，第二次打开还是触发
                this.$refs.ruleForm.resetFields();
            }
            this.ruleForm.T_mid = e.Id
            this.condition = 'add'
            this.ruleForm.T_name = ''
            this.dialogVisible = true
            this.titles = "添加分公司"
        },
        clickNew() { //新增
            // if (this.$refs.ruleForm) { //为了验证第一次触发表单验证，第二次打开还是触发
            // 	this.$refs.ruleForm.resetFields();
            // }
            Object.keys(this.ruleForm).forEach(key => { if (key != 'T_type') { this.ruleForm[key] = "" } });
            this.dialogVisible = true
            this.titles = "新增公司"
            this.condition = 'add'
        },
        SetEdit(e) { //编辑公司
            console.log('编辑公司', e)
            if (this.$refs.ruleForm) { //为了验证第一次触发表单验证，第二次打开还是触发
                this.$refs.ruleForm.resetFields();
            }
            this.condition = 'Edit'
            this.ruleForm.T_name = e.T_name
            this.ruleForm.T_type = e.T_type
            this.ruleForm.T_mid = e.Id
            this.ruleForm.T_Address = e.T_Address
            this.ruleForm.T_coordinate = e.T_coordinate
            this.T_key = e.T_key
            this.dialogVisible = true
            this.titles = "编辑公司"
        },
        SetDel(e) { //删除公司
            ////console.log('删除公司', e)
            this.$confirm('此操作将执行删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            }).then(() => {
                CompanyDel({ Id: e.Id }).then(res => {
                    if (res.data.Code === 200) {
                        this.GetCompanyTreeApi()
                        this.$message.success('操作成功，公司已删除')
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        async Gohome(e) {
            var res = await this.GetCompanyEntryApi(e)
            if (res.data.Code === 200) {
                console.log('跳转', e, res)
                // this.isintelligent
                // return
                this.$store.dispatch('SETNAV', [])
                this.$store.dispatch('SETPANY', {
                    plan: e.T_key,
                    T_name: e.T_name,
                    bl: true
                }) //缓存到vuex
                this.$router.replace('/index')
            }
        },
        GetCompanyEntryApi(e) {
            console.log('pid', e)
            return new Promise(resolve => {
                CompanyEntry({ T_pid: e.Id }).then(res => {
                    resolve(res)
                })
            })
        },
        inputValue(e) {
            this.pages.T_name = e
            this.pages.page = 1
            this.GetCompanyTreeApi()

        },

        handleSizeChange(e) { //每页多少条
            // ////console.log('每页多少条', e)
            this.pages.page_z = e
            this.GetCompanyTreeApi()
        },
        handleCurrentChange(e) { //跳转页
            ////console.log('跳转页', e)
            this.pages.page = e
            this.GetCompanyTreeApi()
        },
    }
}
</script>

<style lang="scss">
.input-cards{
    position: absolute;
    right: 30px;
    bottom: 40px;
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.setstyle {
    min-height: 300px;
    min-width: 400px;
    padding: 0 !important;
    margin: 0;
    overflow: auto;
    cursor: default !important;
}

.Company {
    overflow: hidden;
    display: flex;
    height: 100vh;
    flex-direction: column;

    .Company-T {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: rgba(#fff, 1);
        height: 70px;
        padding: 15px 10px;
        box-shadow: 5px 5px 10px -10px #000;

        .CompanyL {
            display: flex;
            align-items: center;

            .RightsManagement-searchs {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 20px;

                .Companyit-mai1-btn {}

                .RightsManagement-searchs1 {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .RightsManagement-searchsMai {
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }

    .Company-B {
        background: rgba(#fff, 1);
        margin: 10px;
        padding: 20px 30px;
    }
}
</style>

<template>
    <div class="amap-container">
        <div id="container" ref="mapContainer" style="width:100%;height:100vh;"></div>
    </div>
</template>

<script>
  import html2canvas from "html2canvas";
// 起点Icon
const startIcon = new AMap.Icon({
    // 图标尺寸
    size: new AMap.Size(25, 34),
    // 图标的取图地址
    image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
    // 图标所用图片大小
    imageSize: new AMap.Size(135, 40),
    // 图标取图偏移量
    imageOffset: new AMap.Pixel(-9, -3)
});
//途径点
const througIcon = new AMap.Icon({
    size: new AMap.Size(25, 34),
    image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
    imageSize: new AMap.Size(135, 40),
    imageOffset: new AMap.Pixel(-51.2, -3)
});
//终点
const endIcon = new AMap.Icon({
    size: new AMap.Size(25, 34),
    image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
    imageSize: new AMap.Size(135, 40),
    imageOffset: new AMap.Pixel(-95, -3)
});
export default {
    props: ['MapList'],
    data() {
        return {
            map: null,
            polyline: null,
            markers: [],
            center: [106.605499,26.622199], // 中心点
        };
    },
    watch: {
        MapList: {
            handler: function(data) {
                let arr = data || []
                if(arr.length!=0){this.addMarkers(arr)}else{this.map.setZoom(0);}
            },
            deep: true
        }
    },
    mounted() {
        setTimeout(() => {
            this.initMap() // 异步加载（否则报错initMap is not defined）
        }, 1000);
    },
    methods: {
        // 初始化地图
        initMap() {
            this.map = new AMap.Map('container', {
                resizeEnable: true, // 窗口大小调整
                center: this.center,
                // WebGLParams: {
                //     preserveDrawingBuffer: true
                // },
            });
        },
        addMarkers(list) {
            let self = this
            if (this.map) {
                this.map.clearMap(); // 清除地图上的所有覆盖物（可选）
            }
            if (this.markers.length > 0) {
                this.map.remove(this.markers);
                this.markers = [];
            }
            var path = [];
            var mokerData = [];
            this.map.setZoom(16);
            this.map.setCenter([list[0].T_site.split(',')[0],list[0].T_site.split(',')[1]])
            for(let obj of list){
                let site = obj.T_site.split(',')
                if(Number(site[0])>1){
                    path.push([site[0],site[1]])
                    mokerData.push([site[0],site[1],obj.T_name, obj.T_t, obj.T_rh, obj.T_time,obj.T_ist,obj.T_ish])
                }
            }
            path = path.reverse()
            mokerData = mokerData.reverse()

            AMap.convertFrom(path, 'jps', function (status, result) {
                if(result.info === 'ok'){
                    self.drawTrajectory(result.locations)//轨迹线
                    mokerData.forEach((item,index) => {
                        let marker = new AMap.Marker({
                            position: [item[0], item[1]],
                            offset: new AMap.Pixel(-12, -30), // 坐标点偏移量
                            icon: index === 0 ? startIcon : index === result.locations.length - 1 ? endIcon : througIcon,
                        });
                        marker.content = item[6]==2 && item[7]==1?
                        `<div style="padding:10px;color:#000;font-size:12px;line-height: 20px;">
                            <div style="font-weight: bold;font-size:12px;">${mokerData[0][2]}<div>
                            <div style="font-weight: 100;font-size:12px;">湿度：${mokerData[0][4]}%<div>
                            <div style="font-weight: 100;font-size:12px;">时间：${mokerData[0][5]}<div>
                        </div>`
                        : item[6]==1 && item[7]==2?`<div style="padding:10px;color:#000;font-size:12px;line-height: 20px;">
                            <div style="font-weight: bold;font-size:12px;">${mokerData[0][2]}<div>
                            <div style="font-weight: 100;font-size:12px;">温度：${mokerData[0][3]}°C<div>
                            <div style="font-weight: 100;font-size:12px;">时间：${mokerData[0][5]}<div>
                        </div>`
                        :item[6]==2 && item[7]==2?`<div style="padding:10px;color:#000;font-size:12px;line-height: 20px;">
                            <div style="font-weight: bold;font-size:12px;">${mokerData[0][2]}<div>
                            <div style="font-weight: 100;font-size:12px;">时间：${mokerData[0][5]}<div>
                        </div>`
                        :`<div style="padding:10px;color:#000;font-size:12px;line-height: 20px;">
                            <div style="font-weight: bold;font-size:12px;">${mokerData[0][2]}<div>
                            <div style="font-weight: 100;font-size:12px;">温度：${mokerData[0][3]}°C<div>
                            <div style="font-weight: 100;font-size:12px;">湿度：${mokerData[0][4]}%<div>
                            <div style="font-weight: 100;font-size:12px;">时间：${mokerData[0][5]}<div>
                        </div>`
                        marker.on('click', self.markerClick);
                        marker.emit('click', {
                            target: marker
                        }); //默认初始化不出现信息窗体,打开初始化就出现信息窗体
                        self.map.add(marker);
                        self.markers.push(marker);
                    });
                }
            })
            
        },
        markerClick(e) {
            var infoWindow = new AMap.InfoWindow({
                offset: new AMap.Pixel(0, -30)
            })
            infoWindow.setContent(e.target.content);
            if(e.lnglat!=undefined){
                infoWindow.open(this.map, e.target.getPosition());
            }
        },
        drawTrajectory(data) {
            // 清除旧轨迹（如果存在）
            if (this.polyline) this.polyline.setMap(null);
            // 创建新轨迹
            this.polyline = new AMap.Polyline({
                path: data,
                strokeColor: "#3d6eff",
                strokeOpacity: 1,
                strokeWeight: 5,
                showDir: true,
                strokeStyle: "solid",
            });
            // 将新轨迹添加到地图
            this.map.add(this.polyline);
        },
    },
    beforeDestroy() {
        console.log('销毁1')
        if (this.markers) {
            this.map.remove(this.markers);
            this.markers = [];
        }
        if (this.map) {
            this.map.clearMap(); // 清除地图上的所有覆盖物（可选）
            this.map.destroy();  // 销毁地图实例
            this.map = null;     // 将地图实例置为空，帮助垃圾回收
        }
    },
}
</script>

<style>
.maps {
    width: 100%;
    height: 100vh;
}

.amap-container {
    width: 100%;
    height: 100%;
}
</style>

export let arrMov = [
    {
        T_ProductID: 'NEXIgzTydhbYpGVDuaUZjqKMQJRPnCFf',//空开
        key:['d_switch1','d_switch2','d_switch3','d_switch4'],
        arrkey:{
            d_name: "",
            d_state: 0,
            d_time: "",
            name: "d_name",
            swipers:'d_state',
            d_en:1,// 是否启用，0 不展示删除1 正常显示
        }
    },
    {//时控器
        T_ProductID: 'YQdRbnXN',
        key:['ch1','ch2','ch3','ch3','ch4'],
        arrkey: {
            state: 0,
            name: "name",
            time: "",
            swipers:'state',
            d_en:1,// 是否启用，0 不展示删除 1 正常显示
        },
    }
]

export function MovingFun(data) {
    /**
     * 1.先判断T_ProductID是应该取哪一个模板
     * 2.在判断传过来的T_dataJson是否为null，为null直接给第一个模板
     * 3.部位null在取对应的key组成数组
     */
    if (data.Data.T_dataJson) {//有值
	     let array = Object.keys(data.Data.T_dataJson)
        const reslut = arrMov.find(item=>item.T_ProductID==data.Data.T_ProductID)
        let setArr = []
        for (let i = 0; i < array.length; i++) {
            if(reslut.key.includes(array[i])){//对应上
                data.Data.T_dataJson[array[i]].keys = array[i]
                data.Data.T_dataJson[array[i]].names = reslut.arrkey.name
                data.Data.T_dataJson[array[i]].swiperName = reslut.arrkey.swipers
                data.Data.T_dataJson[array[i]].T_sn = data.Data.T_sn
				
				data.Data.T_dataJson[array[i]].T_online = data.Data.T_online
				data.Data.T_dataJson[array[i]].T_password = data.Data.T_password
				
                setArr.push(data.Data.T_dataJson[array[i]])
            }
        }
        data.Data.T_dataJson = setArr
    } else {//里面没有值
        data.Data.T_dataJson = []
    }
    return data
}



export function steTableDataFn(data){
    const reslut = arrMov.find(item=>item.T_ProductID==data.T_ProductID)
    let array = data.T_ProductJson.T_TabDataJson
    let arr = []
    for (let i = 0; i < array.length; i++) {
        if(reslut.key.includes(array[i].T_tab)){//对应上
            arr.push(array[i])
        }
    }
    return arr
}   
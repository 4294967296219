export function Tsnid(e){
	var arr = []
	if(e.length!=0){
		e.forEach(function(obj) {
			var objs = obj.T_sn + ',' + obj.T_id
			arr.push(objs)
		})
		return arr.join('|')+"|"
	}else{
		return ''
	}
}

/**
 * 
 * @param {object} e 
 * @returns string
 */
export function sniJoin(e){
	var arr = []
	if(e.length!=0){
		e.forEach(function(obj) {
			var objs = obj.T_sn + ',' + obj.T_id + ',' + obj.T_sort
			arr.push(objs)
		})
		return arr.join('|')+"|"
	}else{
		return ''
	}
}
<template>
    <div class="">
        <el-popover
        v-for="(item, index) in datas" :key="index"
        placement="right" width="400" trigger="click">
            <el-table :data="gridData" border>
                <el-table-column width="150" property="date" label="开始时间"></el-table-column>
                <el-table-column width="150" property="name" label="结束时间"></el-table-column>
                <el-table-column label="查看">
                    <el-button type="info" size="small">查看</el-button>
                </el-table-column>
            </el-table>
            <div slot="reference" class="mapradio">
                <i class="el-icon-location-information" style="font-size: 18px;color:#9b9faf;"></i>
                <div class="mapradio_txt">
                    <div>{{ item.T_name }}</div>
                    <div>{{ item.T_sn }}</div>
                </div>
            </div>
        </el-popover>
    </div>
</template>

<script>
export default {
    props:{
        datas: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            gridData: []
        }
    },
}
</script>
<style lang="scss">
.mapradio{
    padding: 5px 0;
    color:#000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_txt{
        flex: 1;
        margin-left: 10px;
        font-size: 14px;
        cursor: pointer;
        color: #9b9faf;
    }
}
</style>
<template>
    <div class="">
        <el-checkbox-group v-model="checkboxData">
            <el-checkbox v-for="(item, index) in datas" :label="item.T_sn"
                    style="width: 100%;padding:10px 0;margin-right: 0;" :key="index">
                    <div>{{ item.T_name }}</div>
                    <div>{{ item.T_sn }}</div>

                </el-checkbox>
        </el-checkbox-group>
    </div>
</template>

<script>
export default {
    props:{
        datas: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            checkboxData: [],
        }
    },
    created() {

    },
    mounted() {
    }
}
</script>
<style lang="scss">
/* @import url(); 引入css类 */
</style>
<template>
    <div class="mapShow">
        <div class="mapShow-mai">
            <div ref="mapRef">
                <!-- <Map2 v-if="radios=='1'" :MapList="MapList"></Map2> -->
                <realtimeMap ref="mapRefs" :selectData="selectData" :radios="radios" :showCar="showCar"></realtimeMap>
            </div>
            <div class="mapShow-mai-Topnav">
                <div>
                    <!-- <div>
						<el-button type="primary" size="mini" @click="PirTime(0)">今天</el-button>
						<el-button type="primary" size="mini" @click="PirTime(1)">近一周</el-button>
						<el-button type="primary" size="mini" @click="PirTime(2)">近两周</el-button>
					</div> -->
                </div>
                <div class="mapShow-mai-Topnav-R" v-if="radios == 1 && radio">
                    <el-date-picker v-model="TimeValue" type="datetimerange" range-separator="至"
                        :picker-options="pickerOptions" start-placeholder="开始日期" end-placeholder="结束日期" align="right"
                        @change="Timechange" :clearable="false">
                    </el-date-picker>
                    <div 
                    @click="show2=!show2"
                    style="color: #5f6477;text-align: center;padding-top: 5px;user-select: none;cursor: pointer;"><i class="el-icon-arrow-down"></i></div>
                    <transition name="el-zoom-in-top">
                        <div class="mapShow-mai-Topnav1" v-if="show2">
                            <div class="mapShow-mai-Topnav-mods1">
                                <div class="mapShow-mai-Topnav-mods1-L">
                                    <i class="el-icon-info" style="font-size: 18px;"></i>
                                    <div style="margin-left: 5px;font-weight:400;letter-spacing: 1.5px;">
                                        点击相应时间段查询轨迹
                                    </div>
                                </div>
                                <div class="mapShow-mai-Topnav-mods1-R" style="display: flex;align-items: center;">
                                    <div @click="show2 = false">关闭</div>
                                </div>
                            </div>
                            <div v-if="TimeList == null"  
                            style="color: #5f6477;text-align: center;font-size: 12px;padding: 20px;">没有更多时间</div>
                            <div class="mapShow-mai-Topnav-mods2" v-else>
                                <template v-if="TimeList.length != 0">
                                    <div class="mapShow-mai-Topnav-mod" v-for="(item, index) in TimeList" :key="index"
                                        @click="XaunTime(item)">
                                        <div class="mapShow-mai-Topnav-mod1">
                                            {{ item.T_Ut_start | SheTime(item.T_Ut_start) }}
                                        </div>
                                        <div class="mapShow-mai-Topnav-mod1">
                                            {{ item.T_Ut_end | SheTime(item.T_Ut_end) }}
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div>时间段为空，请选择其他时间段</div>
                                </template>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
            <div class="mapShow-mai-nav">
                <div class="mapShow-mai-nav-mai">
                    <div class="tabs">
                        <div class="tabs_line">
                            <div class="tabs_line_car" 
                            :class="radios==index?'tabs_active':''" 
                            @click="qietabs(index)"
                            v-for="item,index in tabsData" :key="index">{{item}}</div>
                        </div>
                        <el-button type="primary" :disabled="showCar==0?true:false"  size="small" @click="canvasIt">截图</el-button>
                    </div>
                    <div class="mapShow-mai-nav-tit">
                        <div class="mapShow-mai-nav-tit-L">
                            <img src="@/assets/img/mp.png" style="width: 25px;height:25px;">
                            <input class="mapShow-mai-navInput" v-model="pages.T_name" type="text"
                                placeholder="请输入名称,Sn查询" @input="searchs" />
                        </div>
                        <div class="mapShow-mai-nav-tit-R">
                            <i class="el-icon-search"></i>
                        </div>
                    </div>
                    <div style="padding: 0 10px 10px 10px;border-bottom:1px solid #ebeef5;">
                        <el-select v-model="pages.T_calssid" slot="prepend" placeholder="请选择" @input="selects">
                            <el-option v-for="item in options" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="mapShow-mai-nav-cont" @scroll="scroll">
                        <div style="padding:10px;">
                            <el-checkbox-group v-if="radios=='0'" v-model="checkedCities">
                                <el-checkbox @change="handleChange($event,item)" v-for="(item,index) in radioData"
                                 :label="item.T_sn" :key="index" style="width: 80%;padding: 10px 0;">
                                    {{item.T_name}}
                                </el-checkbox>
                            </el-checkbox-group>
                            <el-radio-group v-model="radio" v-else>
                                <el-radio :label="item.T_sn" v-for="(item, index) in radioData" :key="index"  @change="handleChange($event,item)"
                                style="width: 80%;padding: 11.42px 0;">{{item.T_name}}</el-radio>
                            </el-radio-group>
                        </div>
                       
                        <!-- <div class="radios" :class="ivIndex === index ? 'radios-on' : 'radios'"
                            v-for="(item, index) in radioData" :key="index" @click="clickNav(item, index)">
                            <div class="radios-L">
                                <i class="el-icon-location-information" style="font-size: 18px;"></i>
                                <div class="radios-Ltxt">
                                    <div class="radios-Ltxt1">{{ item.T_name }}</div>
                                    <div class="radios-Ltxt2">{{ item.T_sn }}</div>
                                </div>
                            </div>
                            <div class="radios-R">
                                <i class="el-icon-arrow-right"></i>
                            </div>
                        </div> -->
                    </div>
                    <div class="mapShow-mai-nav-pag">
                        <img src="@/assets/img/bdMap.png" alt="" srcset="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import AMapLoader from "@amap/amap-jsapi-loader";
import html2canvas from "html2canvas";
import domToImage from 'dom-to-image';
import realtimeMap from '../../components/realtimeMap.vue'
import Map2 from '../../components/maps.vue'
import {
    ClassList,
    DevSenList,
    DataList,
    DeviceTaskList, SenDataReal
} from '../../api/dataDisplay/mapShow.js'
import {
    formatDateChart
} from '../../utils/Times.js'
// 设置安全密钥
// window._AMapSecurityConfig = {
// 	securityJsCode: '4ddae7ac063e1cdea8b4ebcdfd0d2dab',//安全密钥
// }
export default {
    components: {
        Map2,realtimeMap
    },
    data() {
        return {
            showCar:1,
            selectData: [],
            checkedCities:[],//多选的数据
            radio:'',//单选的数据
            tabsData:['实时轨迹','历史轨迹'],
            radios: 0,
            times: null,
            ParamsIt: {
                T_sn: '',
                T_id: '',
                page: 1,
                page_z: 99999,
            },
            ivIndex: null,
            pickerOptions: {
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date(new Date().toLocaleDateString()).getTime();
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date(new Date().setHours(0, 0, 0, 0)).getTime() - 86400000 * 7;
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近两周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date(new Date().setHours(0, 0, 0, 0)).getTime() - 86400000 * 14;
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            value: '所有选择',
            options: [],
            Page_size: 1,
            radioData: [],
            TimeValue: [
                new Date(new Date().setHours(0, 0, 0, 0)).getTime(), new Date()
            ],
            TimeList: [],
            pages: {//导航列表提交参数
                T_sn: '',
                T_name: '',
                T_calssid: '',
                T_MapShow: 1,
                page: 1,
                page_z: 20,
            },
            pages1: { //获取时间段
                T_sn: '',
                Time_start: '',
                Time_end: '',
                page: 1,
                page_z: 99999,
            },
            pages2: { //获取轨迹数据
                T_sn: '',
                T_id: '',
                Time_start: '',
                Time_end: '',
                page: 1,
                page_z: 99999,
            },
            radio: [],
            show2: false,
            navNum: 0,
            map: null,
            page: 1,
            mouseTool: null,
            MapList: [],
            debounceTimer:null
        }
    },
    mounted() {
        // this.initMap()
        this.GetClassListApi()
        this.GetDevSenListApi()
        // window.onresize = () => (() => {
        // 	this.initMap()
        // })();
    },
    filters: {
        SheTime(time) {
            return formatDateChart(time)
        }
    },
    methods: {
        qietabs(index) {
            //清空地图上的所有点
            if(index==1){
                this.$refs.mapRefs.clearsseAll()
            }
            this.radios = index
            this.checkedCities = []
            this.radio = ''
            this.selectData = []
            //清空地图
            this.$refs.mapRefs.newMarker() 
            
            
        },
        handleChange(enevt,value) {
            if (this.radios == 0) {//实时轨迹
                this.selectData = this.checkedCities
                if(enevt){//选中绘制轨迹
                    this.$refs.mapRefs.initSSE(value.T_sn,value.T_id)
                }else{//取消之后移除
                    // this.$refs.mapRefs.removePolyline(value.T_sn)
                    this.$refs.mapRefs.initSSEclose(value.T_sn)
                    this.$refs.mapRefs.removePolyline(value.T_sn)
                }
            } else {//历史轨迹
                this.selectData = [this.radio]
                this.TimeList = []
                this.ParamsIt.T_sn = value.T_sn
                this.ParamsIt.T_id = value.T_id
                this.pages1.T_sn = value.T_sn
                this.pages1.T_id = value.T_id
                this.pages1.Time_start = formatDateChart(this.TimeValue[0])
                this.pages1.Time_end = formatDateChart(this.TimeValue[1])
                //清空地图
                this.$refs.mapRefs.newMarker()  
                this.GetDeviceTaskListApi()
            }
		},
        GetDeviceTaskListApi() {//获取历史时间列表Api
            DeviceTaskList(this.pages1).then(res => {
                if (res.data.Code === 200) {
                    this.show2 = true
                    this.TimeList = res.data.Data.Data
                }

            })
        },
        XaunTime(item) {//点击自定义时间列表
            this.pages1.Time_start = formatDateChart(item.T_Ut_start)
            this.pages1.Time_end = formatDateChart(item.T_Ut_end)
            this.GetDataListApi()
        },
        Timechange(e) {//点击时间组件选择
            if (e == null) {
                this.pages1.Time_start = ''
                this.pages1.Time_end = ''
                this.pages2.Time_start = ''
                this.pages2.Time_end = ''
            } else {
                this.pages1.Time_start = formatDateChart(e[0])
                this.pages1.Time_end = formatDateChart(e[1])

                this.pages2.Time_start = formatDateChart(e[0])
                this.pages2.Time_end = formatDateChart(e[1])
                this.pages1.page = 1
                this.GetDataListApi()
            }

        },
        GetDataListApi() {//加载轨迹
            let self = this
            self.$refs.mapRefs.newMarker()   

            const loading = this.$loading({
                lock: true,
                text: '获取轨迹  请稍等',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            DataList(this.pages1).then(res => {
                if (res.data.Code==200) {
                    let arr = res.data.Data.DeviceSensor_data || []
                    loading.close();
                    if (arr.length != 0) {
                        let mapData = {
                            name: arr[0].T_id,
                            path : []
                        }
                        arr.forEach(item => {
                            let [x, y] = item.T_site.split(',')
                            let j = [Number(x),Number(y) ,item.T_name, item.T_t, item.T_rh, item.T_time,item.T_ist,item.T_ish]
                            mapData.path.push(j)
                        })
                        mapData.path = mapData.path.reverse();
                        console.log(mapData,self.$refs.mapRefs.endMarkers)
                        if(Object.keys(self.$refs.mapRefs.endMarkers).length>0){
                            self.$refs.mapRefs.stopAnimation()
                        }
                        self.$refs.mapRefs.addline(mapData)//添加一条
                    }else{
                        self.$refs.mapRefs.lineArr = []
                    }
                }
            })
        },
        shuffleArray(array) {
            for (let i = array.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [array[i], array[j]] = [array[j], array[i]]; // 使用解构赋值进行元素交换
            }
            return array;
        },


        canvasIt() {//截图
            let self = this
            this.showCar = 0
            setTimeout(() => {
                const mapsRef = this.$refs.mapRef
                window.pageYOffset = 0;
                document.documentElement.scrollTop = 0;
                document.body.scrollTop = 0;
                html2canvas(mapsRef, {
                    backgroundColor: null, //画出来的图片有白色的边框,不要可设置背景为透明色（null）
                    useCORS: true, //支持图片跨域
                    scale: 1.5, //设置放大的倍数
                }
                ).then(canvas => {
                    //截图用img元素承装，显示在页面的上
                    let img = new Image();
                    img.src = canvas.toDataURL('image/jpeg'); // toDataURL :图片格式转成 base64
                    // document.getElementById('test').appendChild(img); // 这是看截图的页面承载，可以删掉
                    //如果你需要下载截图，可以使用a标签进行下载
                    let a = document.createElement('a');
                    a.href = canvas.toDataURL('image/png');
                    a.download = new Date().getTime()
                    a.click();
                    self.showCar = 1
                    this.$message.success('已截取屏幕')
                })
            }, 500);
        },
        selects() {
            this.radioData = []
            this.pages.page = 1
            this.GetDevSenListApi()
        },
        scroll(e) {//滚动加载导航
            let isBottom = e.target.scrollTop + e.target.clientHeight == e.target.scrollHeight
            if (isBottom && this.pages.page < this.Page_size) {
                this.pages.page = this.pages.page + 1
                this.GetDevSenListApi()
            }
        },
        clickNav(item, index) {//点击导航
            clearInterval(this.times)
            this.times = null
            this.MapList = []
            this.TimeList = []
            this.ParamsIt.T_sn = item.T_sn
            this.ParamsIt.T_id = item.T_id

            this.pages1.T_sn = item.T_sn
            this.pages1.T_id = item.T_id
            this.pages1.Time_start = formatDateChart(this.TimeValue[0])
            this.pages1.Time_end = formatDateChart(this.TimeValue[1])
            this.GetDeviceTaskListApi()
            this.ivIndex = index
        },
        
        searchs() {
            clearTimeout(this.debounceTimer)
            this.debounceTimer = setTimeout(() => {
                this.radioData = []
                this.pages.page = 1
                this.GetDevSenListApi()
            }, 500);
            console.log('搜索',this.pages.T_name)
           
        },
        
        
        GetDevSenListApi() {//列表
            DevSenList(this.pages).then(res => {
                if (res.data.Code === 200) {
                    this.Page_size = res.data.Data.Page_size
                    console.log('导航列表',res)
                    let arr = res.data.Data.DeviceSensor_lite || []
                    this.radioData = [ ...this.radioData,...arr]
                }
            })
        },
        GetClassListApi() {//select列表
            var that = this
            ClassList({}).then(res => {
                if (res.data.Code === 200 && res.data.Msg === 'ok!') {
                    var Arr = res.data.Data.Data
                    Arr.forEach(function (item) {
                        var obj = {
                            value: '',
                            label: ''
                        }
                        obj.value = item.Id
                        obj.label = item.T_name
                        that.options.push(obj)
                    })
                    that.options.unshift({
                        value: '',
                        label: '所有分类'
                    })
                }
            })
        },
    },
}
</script>

<style lang="scss">
@import "@/assets/css/dataDisplay/mapShow.scss";
.tabs{
    border-bottom: 1px solid #e4e7ed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #303133;
    font-size: 14px;
    cursor: pointer;
    user-select: none;
    padding: 0 10px;
    &_line{
        display: flex;
        gap: 20px;
        &_car{
            padding: 20px 0;
        }
    }
    
}
.tabs_active {
    color: #409eff;
}
</style>
